<template>
<div style="width: 100%;height: 100%" v-cloak>
    <div class="header">
        <img
            :src="isDarkMode ? require('../assets/images/base/ic_public_back_dark.png') : require('../assets/images/base/ic_public_back.png')"
            alt="">
        <span v-text="playMusic.title"></span>
    </div>
    <div class="content">
        <div class="round-box">
            <div class="round-2-box">
                <img :src="'https://new.gsleep.com'+playMusic.image" alt="">
            </div>
        </div>
        <div class="content-bottom">
            <div class="music-btn">
                <img src="../assets/images/30.png" @click="changeAudioMode" alt="" v-if="playMode == 'list'">
                <img src="../assets/images/29.png" @click="changeAudioMode" alt="" v-if="playMode == 'single'">
                <img src="../assets/images/31.png" @click="changeAudioMode" alt="" v-if="playMode == 'round'">

                <img src="../assets/images/7.png" @click="loveMusic" v-if="isCollect == 0">
                <img src="../assets/images/13.png" @click="loveMusic" v-if="isCollect == 1">
                <div class="countdown">
                    <div>
                        <span v-if="countdownSetTime != 0" v-text="formatSeconds(countdownTime)"></span>
                    </div>
                    <img src="../assets/images/6.png" @click="showCountdown">
                </div>
                <img src="../assets/images/5.png" @click="audioListShow">
            </div>
            <div class="progress-bar">
                <span v-text="currentTimes"></span>
                <div class="progress" ref="progressBox" @click="progressClick">
                    <div ref="progress" class="progress-round"></div>
                </div>
                <span v-text="durations"></span>
            </div>
            <div class="music-control">
                <img @click="prevAudio" src="../assets/images/4.png" alt="">
                <img @click="pauseAudio" class="play" src="../assets/images/2.png" alt="" v-if="playStatus">
                <img @click="playAudio" class="play" src="../assets/images/22.png" alt="" v-else>
                <img @click="nextAudio" src="../assets/images/3.png" alt="">
            </div>
        </div>
        <div class="mask" @click="closeAll" v-if="maskStatus"></div>
        <div class="music-box">
            <div class="music-list-box" v-if="showList">
                <div class="music-list-title-box">
                    <div class="music-list-title">
                        <div :class="{'music-list-title-active': playState == 'play'}" @click="playState = 'play'">
                            <span>当前系列播放</span>
                        </div>
                        <div :class="{'music-list-title-active': playState == 'collect'}"
                             @click="playState = 'collect'">
                            <span>收藏列表播放</span>
                        </div>
                    </div>
                </div>
                <div class="music-list" v-if="playState == 'play'">
                    <div v-for="(item, index) in audioList" :key="index">
                        <div class="list" @click="changeAudio(index)">
                            <span v-text="item.title"></span>
                            <img src="../assets/images/1.png" alt="" v-if="item.id == playMusic.id">
                        </div>
                    </div>
                </div>
                <div class="music-list" v-if="playState == 'collect'">
                    <div v-for="(item, i) in collectList" :key="i">
                        <div class="list" @click="changeAudio(i)">
                            <span v-text="item.title"></span>
                            <div>
                                <img src="../assets/images/1.png" alt="" v-if="item.id == playMusic.id">
                                <img src="../assets/images/error.png" alt="" @click="cancelCollect(item.id)">
                            </div>

                        </div>
                    </div>
                </div>
                <div class="music-bottom">
                    <span class="music-list-title-active" @click="closeAll()">关闭</span>
                </div>
            </div>
        </div>

        <div class="music-box">
            <div class="music-list-box" v-if="showCountdownList">
                <div class="music-list-title-box center" style="border: 0;">
                    <div class="music-list-title font16px">
                        <span>倒计时</span>
                    </div>
                </div>
                <div class="music-list" style="padding: 0;height: auto">
                    <div class="list">
                        <span>不开启</span>
                        <input type="radio" name="time" v-model="setTimes" value="0">
                    </div>
                    <div class="list">
                        <span>当前声音播放完后</span>
                        <input type="radio" name="time" v-model="setTimes" value="curr">
                    </div>
                    <div class="list">
                        <span>10分钟</span>
                        <input type="radio" name="time" v-model="setTimes" value="10">
                    </div>
                    <div class="list">
                        <span>15分钟</span>
                        <input type="radio" name="time" v-model="setTimes" value="20">
                    </div>
                    <div class="list">
                        <span>30分钟</span>
                        <input type="radio" name="time" v-model="setTimes" value="30">
                    </div>
                    <div class="list">
                        <span>60分钟</span>
                        <input type="radio" name="time" v-model="setTimes" value="60">
                    </div>
                </div>
                <div class="music-list-bottom music-list-title-active">
                    <div class="music-list-title font16px diver" @click="closeAll">
                        <span>取消</span>
                    </div>
                    <div class="music-list-title font16px" @click="countdown">
                        <span>确认</span>
                    </div>
                </div>
            </div>
        </div>
        <audio class="audio" ref="audio" autoplay
               @ended="overAudio"
               @pause="onPause"
               @play="onPlay"
               @canplay="getDuration"
               @timeupdate="updateTime"
               :src="playMusic.url"></audio>
    </div>
</div>
</template>

<script>
let countdownBox;
export default {
    name: "musicPlay",
    data() {
        return {
            isDarkMode: false,
            playMusic: {
                title: '',
                image: '',
                url: ''
            },
            setTimes: 0,
            musicTitle: '音乐名称',
            musicImage: '',
            duration: 0,
            currentTime: 0,
            currentTimes: '00:00',
            durations: '00:00',
            playStatus: false,
            aid: 0,
            audioList: [],
            collectList:[],
            playIndex: 0,
            collectIndex: 0,
            maskStatus: false,
            showList: false,
            playMode: 'list', // list 列表播放 single 单曲循环 round 随机
            showCountdownList: false, // 倒计时显示
            countdownSetTime: 0,
            countdownTime: 0,
            playState: 'play', // play 当前系列播放 collect 收藏
            playPage: 1, // 播放页码
            playTotalPage: 1, // 播放总页码
            collectPage: 1, // 收藏播放页码
            collectTotalPage: 1, // 收藏播放页码
            isCollect: 0, // 是否收藏
        }
    },
    watch: {
        playIndex() {
            this.getCurrMusicCollect()
        }
    },
    computed: {
    },
    methods: {
        currCountdown() {
            this.countdownTime = this.duration - this.currentTime;
            this.countdownSetTime = 'curr';
            countdownBox = setInterval(() => {
                if (this.countdownTime <= 0) {
                    this.onPause();
                    this.cancelCountdown()
                } else {
                    this.countdownTime -= 1;
                }
            }, 1000);
        },
        countdown() {
            this.closeAll()
            if (this.setTimes == 'curr') {
                this.currCountdown();
            } else {
                this.countdownSetTime = this.setTimes;
                this.countdownTime = this.setTimes * 60;
                countdownBox = setInterval(() => {
                    if (this.countdownTime <= 0) {
                        this.onPause();
                        this.cancelCountdown()
                    } else {
                        this.countdownTime -= 1;
                    }
                }, 1000);
            }
        },
        cancelCountdown() {
            clearInterval(countdownBox)
            this.countdownTime = 0;
            this.countdownSetTime = 0;
        },
        changeAudioMode() {
            if (this.playMode == 'list') {
                this.playMode = 'single'
            } else if (this.playMode == 'single') {
                this.playMode = 'round'
            } else if (this.playMode == 'round') {
                this.playMode = 'list'
            }
        },
        progressClick(e) {
            let width = this.$refs.progressBox.clientWidth
            let left = this.$refs.progressBox.getBoundingClientRect().left
            var offset = e.pageX;
            let percentage = ((offset - left) / width).toFixed(2);
            this.$refs.audio.currentTime = percentage * this.duration
        },
        changeAudio(index) {
            if (this.playState == 'play') {
                if (index != this.playIndex) {
                    this.playIndex = index;
                    this.playAudio()
                }
            } else {
                if (index != this.collectIndex) {
                    this.collectIndex = index;
                    this.playAudio()
                }
            }
        },
        audioListShow() {
            this.maskStatus = true;
            this.showList = true;
        },
        showCountdown() {
            this.maskStatus = true;
            this.showCountdownList = true;
        },
        closeAll() {
            this.maskStatus = false;
            this.showList = false;
            this.showCountdownList = false;
        },
        onPause() {
            this.playStatus = false;
        },
        onPlay() {
            this.playStatus = true;
        },
        sum(m, n, curr) {
            let num = Math.floor(Math.random() * Number(m - n + 1) + n);
            if (num == curr) {
                return this.sum(m, n, curr);
            } else {
                return num;
            }
        },
        overAudio() {
            if (this.playStatus) {
                if (this.playMode == 'list') {
                    this.nextAudio();
                } else if (this.playMode == 'single') {
                    this.playAudio();
                } else {
                    let index = this.sum(0, this.audioList.length - 1, this.playIndex);
                    this.playIndex = index;
                }
            }
        },
        getDuration() {
            this.duration = this.$refs.audio.duration;
            this.durations = this.formatSeconds(this.duration);
        },
        updateTime() {
            this.currentTime = this.$refs.audio.currentTime;
            this.currentTimes = this.formatSeconds(parseInt(this.currentTime));
            this.percentage = ((this.currentTime / this.duration).toFixed(4)) * 100;
            this.$refs.progress.style.left = parseInt(this.percentage) - 2.5 + '%';
        },
        nextAudio() {
            if (this.playState == 'play') {
                if (this.playMode == 'round') {
                    let index = this.sum(0, this.audioList.length - 1, this.playIndex);
                    this.playIndex = index;
                } else {
                    if (this.audioList.length - 1 <= this.playIndex) {
                        this.playIndex = 0;
                    } else {
                        this.playIndex += 1;
                    }
                }
                this.playAudio();
            } else {
                if (this.playMode == 'round') {
                    let index = this.sum(0, this.collectList.length - 1, this.collectIndex);
                    this.collectIndex = index;
                } else {
                    if (this.collectList.length - 1 <= this.collectIndex) {
                        this.collectIndex = 0;
                    } else {
                        this.collectIndex += 1;
                    }
                }
                this.playAudio();
            }
        },
        prevAudio() {
            if (this.playState == 'play') {
                if (this.playMode == 'round') {
                    let index = this.sum(0, this.audioList.length - 1, this.playIndex);
                    this.playIndex = index;
                } else {
                    if (this.playIndex <= 0) {
                        this.playIndex = this.audioList.length - 1;
                    } else {
                        this.playIndex -= 1;
                    }
                }
                this.playAudio();
            } else {
                if (this.playMode == 'round') {
                    let index = this.sum(0, this.collectList.length - 1, this.collectIndex);
                    this.collectIndex = index;
                } else {
                    if (this.collectIndex <= 0) {
                        this.collectIndex = this.collectList.length - 1;
                    } else {
                        this.collectIndex -= 1;
                    }
                }
                this.playAudio();
            }
        },
        playAudio() {
            this.$refs.audio.play()
            if (this.playState == 'play') {
                this.playMusic.id = this.audioList[this.playIndex].id
                this.playMusic.title = this.audioList[this.playIndex].title
                this.playMusic.image = this.audioList[this.playIndex].image
                this.playMusic.url = this.audioList[this.playIndex].audio_url
            } else {
                console.log(this.collectIndex)
                this.playMusic.id = this.collectList[this.collectIndex].id
                this.playMusic.title = this.collectList[this.collectIndex].title
                this.playMusic.image = this.collectList[this.collectIndex].image
                this.playMusic.url = this.collectList[this.collectIndex].audio_url
            }
            this.playStatus = true
            this.getCurrMusicCollect()
        },
        pauseAudio() {
            this.$refs.audio.pause()
            this.playStatus = false
        },
        formatSeconds(time) {
            let timeStr = '';
            let stringFormat = (i) => {
                return i < 10 ? `0${parseInt(i)}` : `${parseInt(i)}`;
            }
            let minuteTime = 0;
            let secondTime = 0;
            let hourTime = 0;
            if (time < 60) {
                timeStr = `00:${stringFormat(time)}`
            } else if (time >= 60 && time < 3600) {
                minuteTime = parseInt(time / 60);
                secondTime = parseInt(time % 60);
                timeStr = `${stringFormat(minuteTime)}:${stringFormat(secondTime)}`;
            } else if (time >= 3600) {
                let _t = parseInt(time % 3600);
                hourTime = parseInt(time / 3600);
                minuteTime = parseInt(_t / 60);
                secondTime = parseInt(_t % 60);
                timeStr = `${stringFormat(hourTime)}:${stringFormat(minuteTime)}:${stringFormat(secondTime)}`
            }
            return timeStr;
        },
        getData() {
            this.$axios.get('/api/getMusicAlbum?aid=' + this.aid, {
                headers: {'token': this.token},
            }).then((response) => {
                if (response.data.data.length > 0) {
                    this.audioList = response.data.data;
                    this.playMusic.id = this.audioList[this.playIndex].id
                    this.playMusic.title = this.audioList[this.playIndex].title
                    this.playMusic.image = this.audioList[this.playIndex].image
                    this.playMusic.url = this.audioList[this.playIndex].audio_url
                    this.getCurrMusicCollect()
                }
            })
        },
        getCollect() {
            this.$axios.get('/api/getCollect', {
                headers: {'token': this.token},
            }).then((response) => {
                this.collectList = response.data.data;
            })
        },
        getCurrMusicCollect() {
            this.$axios.get('/api/isCollectMusic?mid=' + this.audioList[this.playIndex].id, {
                headers: {'token': this.token},
            }).then((response) => {
                this.isCollect = response.data.data
            })
        },
        nextPage() {
            if (this.playState == 'play') {
                this.playPage = this.playPage + 1;
            } else {
                this.collectPage = this.collectPage + 1;
            }
        },
        prevPage() {
            if (this.playState == 'play') {
                this.playPage = this.playPage - 1;
            } else {
                this.collectPage = this.collectPage - 1;
            }
        },
        getUrlKey: function (name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
                .exec(location.href) || ['', ""])[1].replace(/\+/g, '%20')) || null
        },
        // 添加播放数量
        addPlayNum() {
            this.$axios.post('/api/addPlayNum', {id: this.aid}, {
                headers: {'token': this.token},
            }).then((response) => {
                console.log(response)
            })
        },
        loveMusic() {
            this.$axios.post('/api/loveMusic', {mid: this.audioList[this.playIndex].id}, {
                headers: {'token': this.token},
            }).then((response) => {
                this.isCollect = response.data.data.status
            })
        },
        cancelCollect(id) {
            this.$axios.post('/api/cancelCollect', {id: id}, {
                headers: {'token': this.token},
            }).then((response) => {
                this.collectList = response.data.data;
            })
        }
    },
    created() {
        this.aid = this.getUrlKey('aid');
        this.token = this.getUrlKey('token');
        this.$nextTick(()=>{
            this.getData();
            this.getCollect();
            this.addPlayNum();
        })
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/musicPlay";
</style>
<style>
.round-2-box {
    -webkit-animation: haha1 8s linear infinite;
}
@-webkit-keyframes haha1 {
    0% {
        -webkit-transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(270deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
</style>
